import React, {useEffect, useState} from 'react'
import {Button} from "indicator-ui";
import {LogoSVG} from "../assets";
import {CustomerServicePollingStyle} from "../styles";
import {MainPoll} from "./MainPoll";
import {CompletedPoll} from "./CompletedPoll";
import {useSearchParams} from "react-router-dom";
import {checkToken} from "../api";

const TYPE_SEARCH_PARAM_NAME = 'type'
const TOKEN_SEARCH_PARAM_NAME = 'token'

export function CustomerServicePolling() {
    const [pollState, setPollState] = useState(undefined)
    const [searchParams] = useSearchParams()

    useEffect(() => {
        const checkFetch = async () => {
            const token = searchParams.get(TOKEN_SEARCH_PARAM_NAME)
            const response = await checkToken(token)
            if (response === null) {
                setPollState(null)
            } else if (response === true) {
                setPollState('completed')
            } else {
                setPollState('poll')
            }
        }
        checkFetch()
    }, [])

    const getMainContent = () => {
        switch (pollState) {
            case 'completed':
                return <CompletedPoll/>
            case 'poll':
                let type = searchParams.get(TYPE_SEARCH_PARAM_NAME)
                const token = searchParams.get(TOKEN_SEARCH_PARAM_NAME)
                switch (type) {
                    case "Task_В обработке":
                        type = 'taskInProcess'
                        break
                    case "Show_Выполнено":
                        type = 'showComplete'
                        break
                    case "Show_Отклонено":
                        type = 'showCancel'
                        break
                    case "Task_Выполнено":
                        type = 'taskComplete'
                        break
                    case "Task_Отклонено":
                        type = 'taskCancel'
                        break
                    default:
                        return undefined
                }
                return <MainPoll token={token} type={type} setPollState={setPollState}/>
            case null:
                return 'Запрещено'
            case undefined:
                return undefined
        }
    }

    return (
        <div className={CustomerServicePollingStyle?.customerServicePolling}>
            <div className={CustomerServicePollingStyle?.header}>
                <div className={CustomerServicePollingStyle?.logo}>
                    <LogoSVG/>
                    <span className={CustomerServicePollingStyle?.label}>Белгородская ипотечная корпорация</span>
                </div>
                <Button customComponent={<a href={'https://bik31.ru/'}/>} size={'small'} hierarchy={'secondary-color'}
                        width={'hug'}>На сайт БИК</Button>
            </div>

            <div className={CustomerServicePollingStyle?.main}>
                {getMainContent()}
            </div>

            <a href={'https://bik31.ru/polzovatelskoe-soglashenie/'}
               target="_blank" rel="noopener noreferrer"
               className={CustomerServicePollingStyle?.footer}>
                Политика конфиденциальности и правила обработки персональных данных
            </a>
        </div>
    )
}