import React from "react";

export default function handleScrollEnd(e: React.UIEvent<HTMLDivElement>, callback: (e: React.UIEvent<HTMLDivElement>) => void) {
    const target = e.currentTarget as HTMLElement;
    if (target) {
        const {scrollTop, scrollHeight, clientHeight} = target;

        if (scrollTop + clientHeight >= scrollHeight) {
            callback(e)
        }
    }
}