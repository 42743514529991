/**
 * Массив структуры данных **Tree**.
 * */
export type TraverseTreeByStackArrayType<T> = T[]
/**
 * Функция обработки узлов.
 * */
export type TraverseTreeByStackProcessType<T> = (item: T) => void
/**
 * Дополнительны настройки.
 * */
export type TraverseTreeByStackOptionsType<T> = {
    /**
     * Функция получения **поддерева** из текущего узла.
     * Если возвращается ```undefined```, то узел - лист.
     * Требуемое возвращаемое значение - поддерево из текущего узла.
     * Входные данные - текущий узел.
     * */
    getArray?: (item: T) => (TraverseTreeByStackArrayType<T> | undefined),
    /**
     * Нужно ли обрабатывать **process-функцией** узлы с поддеревом.
     * По-умолчанию - ```false```.
     * */
    isProcessArray?: boolean,
}

export function traverseTreeByStack<T>(
    arr: TraverseTreeByStackArrayType<T>,
    process: TraverseTreeByStackProcessType<T>,
    options?: TraverseTreeByStackOptionsType<T>
) {
    // Init options
    const getArray = options?.getArray ? options?.getArray : (item: T) => item as TraverseTreeByStackArrayType<T>
    const isProcessArray = options?.isProcessArray ? options?.isProcessArray : true

    // Main function
    const stack: TraverseTreeByStackArrayType<T>[] = [arr]

    while (stack.length > 0) {
        const curArray = stack.pop()
        if (!curArray) continue

        for (const item of curArray) {
            const isArray = getArray(item)

            if (isArray !== undefined) {
                if (isProcessArray) {
                    process(item)
                }
                stack.push(isArray)
            } else {
                process(item)
            }
        }
    }
}