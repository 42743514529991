import React from 'react';
import clsx from "clsx";
import {CalendarDayPropsType} from "../types";
import {CalendarDayStyle} from "../styles";

export function CalendarDay({
                                day,
                                info,
                                holiday,
                                filter,
                                active,
                                mark,
                                onClick,
                                disabled = false,
                                className = CalendarDayStyle
                            }: CalendarDayPropsType) {
    const getClassName = () => {
        return clsx(className?.calendarDay, {
            [className?.active]: active,
            [className?.filter]: filter,
            [className?.holiday]: holiday,
        })
    }
    return (
        <button type={'button'} disabled={disabled} onClick={onClick} className={getClassName()}>
            {mark && <span className={className?.mark}></span>}
            {day && <span className={className?.day}>{day}</span>}
            {info && <span className={className?.info}>{info}</span>}
        </button>
    )
}