import React from 'react'
import {CascadeSelectorPropsType} from "../types";
import {CascadeBlock} from "./CascadeBlock";

export function CascadeSelector({value, options, onChange, multiple, defaultAllHidden}: CascadeSelectorPropsType) {
    const getValue = () => {
        if (Array.isArray(value)) {
            return [...value]
        } else if (value !== undefined && value !== null) {
            return [value]
        }
        return []
    }
    const handleClick = (newValue: any) => {
        const prevState = getValue()
        const isInclude = prevState.includes(newValue)

        if (isInclude) {
            onChange && onChange(prevState.filter(item => item !== newValue))
        } else {
            if (!multiple) {
                onChange && onChange([newValue])
            } else {
                onChange && onChange([...prevState, newValue])
            }
        }
    }

    return <CascadeBlock options={options} noWrapper={true} onClick={handleClick} defaultAllHidden={defaultAllHidden}
                         value={getValue()}/>
}