import clsx from "clsx";
import {TagCountPropsType} from "../types";
import {TagCountStyle} from '../styles'

export function TagCount({count, color = 'gray', size = 'medium'}: TagCountPropsType) {
    return (
        <span className={clsx(TagCountStyle?.tagCount, {
            [TagCountStyle?.color]: color === 'color',
            [TagCountStyle?.gray]: color === 'gray',
            [TagCountStyle?.small]: size === 'small',
            [TagCountStyle?.medium]: size === 'medium',
            [TagCountStyle?.large]: size === 'large',
        })}>{count}</span>
    )
}