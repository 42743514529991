'use client'

import React, {useEffect, useRef, useState} from "react";
import {
    AddStoreValueType,
    FormBuilderPropsType,
    GetStoreValueType,
    RemoveStoreValueType,
    ValidateFunction
} from "../types";
import {useFormData, useIsErrorField, UseIsErrorFieldReturnType} from "@/hooks";
import {formBuilder} from "../lib";
import {KEY_WAY_STORE_NAME} from "../consts";

export function FormBuilder<T extends { [key: string]: any }>({
                                                                  schema,
                                                                  formDataDefault,
                                                                  onChange,
                                                                  onChangeIsError,
                                                                  inputFieldClassName,
                                                                  additionIsErrorFields,
                                                                  onChangeValidateIsError,
                                                                  formDataDefaultListener,
                                                                  inputFieldAdditionProps,
                                                                  sensitiveErrorSearch = true,
                                                              }: FormBuilderPropsType<T>): React.JSX.Element {
    //==================================================================================================================
    // Пока спорная фича, скорее всего лишняя
    const [formBuilderStateStore, setFormBuilderStateStore] = useState<{ [key: string]: any }>({})
    const formBuilderRefStore = useRef<{ [key: string]: any }>({})

    const getStateStoreValue: GetStoreValueType = (key) => {
        return formBuilderStateStore[key]
    }
    const addStateStoreValue: AddStoreValueType = (value, key) => {
        setFormBuilderStateStore(prevState => ({...prevState, [key]: value}))
    }
    const removeStateStoreValue: RemoveStoreValueType = (key) => {
        setFormBuilderStateStore(prevState => {
            const newState = {...prevState}
            delete newState[key]
            return newState
        })
    }
    const getRefStoreValue: GetStoreValueType = (key) => {
        return formBuilderRefStore.current[key]
    }
    const addRefStoreValue: AddStoreValueType = (value, key) => {
        formBuilderRefStore.current[key] = value
    }
    const removeRefStoreValue: RemoveStoreValueType = (key) => {
        delete formBuilderRefStore.current[key]
    }
    //==================================================================================================================

    const privateRefStore = useRef<{ [key: string]: any }>({})
    const getPrivateRefStoreValue: GetStoreValueType = (key) => {
        return privateRefStore.current[key]
    }
    const addPrivateRefStoreValue: AddStoreValueType = (value, key) => {
        privateRefStore.current[key] = value
    }
    const removePrivateRefStoreValue: RemoveStoreValueType = (key) => {
        delete privateRefStore.current[key]
    }

    const {
        formData,
        setFormData,
        setValue,
        getValue,
        deleteArrayItem
    } = useFormData<T>(formDataDefault || ({} as T))

    const {
        isErrorField,
        addErrorField,
        removeErrorField,
        getErrorMessage,
        isError
    } = useIsErrorField({
        additionIsErrorFields: additionIsErrorFields,
        sensitiveSearch: sensitiveErrorSearch,
    })

    const onChangeError: UseIsErrorFieldReturnType = useIsErrorField({
        additionIsErrorFields: additionIsErrorFields,
        sensitiveSearch: sensitiveErrorSearch,
    })

    const validateFunctions = useRef<ValidateFunction[]>([])
    validateFunctions.current = []

    removePrivateRefStoreValue(KEY_WAY_STORE_NAME)

    useEffect(() => {
        if (formDataDefaultListener !== undefined) {
            setFormData(formDataDefaultListener)
        }
    }, [formDataDefaultListener]);

    useEffect(() => {
        onChange && onChange(formData)
    }, [formData])

    useEffect(() => {
        onChangeIsError && onChangeIsError(isError)
    }, [isError])

    useEffect(() => {
        onChangeValidateIsError && onChangeValidateIsError(onChangeError.isError)
    }, [onChangeError.isError])

    return (
        <>
            {
                formBuilder(schema, {
                    keyWay: [],
                    getValue: getValue,
                    setValue: setValue,
                    inputFieldClassName: inputFieldClassName,
                    isErrorField: isErrorField,
                    getErrorMessage: getErrorMessage,
                    addErrorField: addErrorField,
                    removeErrorField: removeErrorField,

                    addOnChangeErrorField: onChangeError.addErrorField,
                    removeOnChangeErrorField: onChangeError.removeErrorField,

                    inputFieldAdditionProps: inputFieldAdditionProps,

                    deleteArrayItemHook: deleteArrayItem,
                    // getStateStoreValue: getStateStoreValue,
                    // getRefStoreValue: getRefStoreValue,
                    // addStateStoreValue: addStateStoreValue,
                    // addRefStoreValue: addRefStoreValue,
                    // removeStateStoreValue: removeStateStoreValue,
                    // removeRefStoreValue: removeRefStoreValue,
                    getPrivateRefStoreValue: getPrivateRefStoreValue,
                    addPrivateRefStoreValue: addPrivateRefStoreValue,
                    removePrivateRefStoreValue: removePrivateRefStoreValue,
                })
            }
        </>
    )
}