import React, {ReactElement} from 'react'
import clsx from "clsx";
import {SwitcherFieldPropsType} from "../types";
import {SwitcherFieldStyle} from "../styles";

export default function SwitcherField({
                                          value,
                                          onChange,
                                          children,
                                          disabled = false,
                                          switcherWrapper,
                                          className = SwitcherFieldStyle
                                      }: SwitcherFieldPropsType): React.JSX.Element {

    const handleChange = () => {
        onChange && onChange(!value)
    }

    const Switcher = ({value, className, disabled}: SwitcherFieldPropsType) => {
        return (
            <button disabled={disabled}
                    onClick={handleChange}
                    className={clsx(className?.switcherField, {
                        [className?.active as string]: !!value,
                    })}>
                {children}
            </button>
        )
    }

    if (switcherWrapper !== undefined) {
        return React.cloneElement(switcherWrapper, {
            children: <Switcher className={className} value={value} disabled={disabled}/>,
            value: value,
            onChange: onChange,
            disabled: disabled
        },)
    }

    return <Switcher className={className} value={value} disabled={disabled}/>
}