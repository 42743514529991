export async function createPoll({token, grade, comment}) {
    const url = process.env.REACT_APP_COLLBACK_URL + `/api/client_task/survey/?token=${token}`
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            grade: grade,
            comment: comment,
        })
    })
    return response.ok
}