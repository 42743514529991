import React from "react"
import clsx from "clsx";
import {RadioFieldItemPropsType} from "../types";

export default function RadioFieldItem({
                                           option,
                                           onClick,
                                           active = false,
                                           disabled = false,
                                           className,
                                       }: RadioFieldItemPropsType): React.JSX.Element {
    if (option.component) {
        return React.cloneElement(option.component, {onClick: onClick, active: active, disabled: disabled})
    }

    return (
        <button onClick={onClick}
                disabled={disabled}
                className={clsx(className?.radioFieldItem, {
                    [className?.active as string]: active,
                })}>
            <div className={className?.checkbox}></div>
            <span className={className?.label}>{option.label}</span>
        </button>
    )
}