import React from 'react'
import {StarSVG} from "../../assets";
import {StarStyle} from "../../styles";

export function StarIcon({active, hover, onClick, onMouseEnter, onMouseLeave}) {
    const getClassName = () => {
        return `${StarStyle.star} ${active ? StarStyle.active : ''} ${hover ? StarStyle.hover : ''}`
    }

    return (
        <button onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onClick={onClick}
                className={getClassName()}>
            <StarSVG/>
        </button>
    )
}