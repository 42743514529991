import React from "react";
import {ru} from "date-fns/locale";
import {eachDayOfInterval, format, isSameDay, isSameMonth, isWithinInterval} from 'date-fns'
import {CalendarDayMetaList, DatePickerMainPropsType} from "../types";
import {DatePickerMainStyle} from "../styles";
import {getIntervalDate} from "../lib";
import {DateGrid} from "./DateGrid";


export function DatePickerMain({
                                   chosenStart,
                                   chosenEnd,
                                   markFun,
                                   infoFun,
                                   target,
                                   onClickDate,
                                   onScrollUp,
                                   onScrollDown,
                               }: DatePickerMainPropsType) {
    const weekDays = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс']

    const setActiveIntervalDays = (days: CalendarDayMetaList): CalendarDayMetaList => {
        const start = chosenStart ? new Date(chosenStart) : undefined
        const end = chosenEnd ? new Date(chosenEnd) : undefined

        return days.map(item => {
            const curDate = new Date(item.date)
            const onInterval = start && end && isWithinInterval(curDate, {start: start, end: end})
            const isActive = (start && isSameDay(start, curDate)) || (end && isSameDay(end, curDate))
            return {...item, filter: !isActive && onInterval, active: isActive}
        })
    }

    const getDates = (targetDate: string): CalendarDayMetaList => {
        const interval = eachDayOfInterval(getIntervalDate(targetDate))
        return interval.map((item) => ({
            day: item.getDate(),
            date: item.toISOString(),
            holiday: [0, 6].includes(item.getDay()),
            info: infoFun && infoFun(item.toISOString()),
            mark: markFun && markFun(item.toISOString()),
            disabled: !isSameMonth(target, item),
        }))
    }

    const getMonth = () => {
        return format(target, "MMMM", {locale: ru});
    }

    const getYear = () => {
        return format(target, "yyyy", {locale: ru});
    }

    const getMainDates = () => {
        return setActiveIntervalDays(getDates(target))
    }

    return (
        <div className={DatePickerMainStyle.datePickerMain}>
            <div className={DatePickerMainStyle.header}>{weekDays.map((item, idx) => <span
                className={DatePickerMainStyle.item} key={idx}>{item}</span>)}</div>
            <div className={DatePickerMainStyle.main}>
                <div className={DatePickerMainStyle.meta}>
                    <span className={DatePickerMainStyle.month}>{getMonth()}</span>
                    <span className={DatePickerMainStyle.year}>{getYear()}</span>
                </div>
                <div className={DatePickerMainStyle.grid}>
                    <DateGrid grid={getMainDates()}
                              onClickDate={onClickDate}
                              onScrollDown={onScrollDown}
                              onScrollUp={onScrollUp}/>
                </div>
            </div>
        </div>
    )
}