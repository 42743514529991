import {
    addWeeks,
    closestIndexTo,
    differenceInDays,
    endOfMonth,
    endOfWeek,
    format,
    isEqual,
    max,
    min, parse,
    startOfMonth,
    startOfWeek
} from "date-fns";
import {NUM_OF_COLS, NUM_OF_ROWS} from "../const";
import {DatePickerChosenIntervalType} from "../types";

export const getIntervalDate = (targetDate: string) => {
    let start = startOfWeek(startOfMonth(targetDate), {weekStartsOn: 1})
    let end = endOfWeek(endOfMonth(targetDate), {weekStartsOn: 1})
    const daysBetween = differenceInDays(end, start)

    if (NUM_OF_COLS * NUM_OF_ROWS > daysBetween) {
        end = endOfWeek(addWeeks(end, 1))
    }
    return {start: start.toISOString(), end: end.toISOString()}
}


export const getNewInterval = (prevState: DatePickerChosenIntervalType, newDate: string): DatePickerChosenIntervalType => {
    const start = prevState.start
    const end = prevState.end

    if (start && isEqual(start, newDate)) {
        return {...prevState, start: undefined}
    }

    if (end && isEqual(end, newDate)) {
        return {...prevState, end: undefined}
    }

    if (start && end) {
        const closestDateIndex = closestIndexTo(newDate, [start, end])
        if (closestDateIndex === 0) {
            return {...prevState, start: newDate}
        } else {
            return {...prevState, end: newDate}
        }
    } else if (start || end) {
        const existDate = start || end
        return {start: min([existDate, newDate]).toISOString(), end: max([existDate, newDate]).toISOString()}
    } else {
        return {start: newDate}
    }
}

export const getInitChosenInterval = ({start, end, multiple, formatIn}: {
    start?: string,
    end?: string,
    multiple?: boolean,
    formatIn?: string
}) => {
    const formatStart = start ? parse(start, formatIn || 'yyyy-MM-dd', new Date()).toISOString() : undefined
    const formatEnd = end ? parse( end, formatIn || 'yyyy-MM-dd', new Date()).toISOString() : undefined
    if (multiple) {
        return {start: formatStart, end: formatEnd}
    }
    return {start: formatStart || formatEnd}
}

export const getInitTargetDate = ({start, end, formatIn}: {
    start?: string,
    end?: string,
    formatIn?: string
}): string => {
    const chosenDate = start || end
    const formatDate = chosenDate ? parse(chosenDate, formatIn || 'yyyy-MM-dd', new Date()).toISOString() : undefined
    if (formatDate) {
        return formatDate
    }
    return (new Date()).toISOString()
}