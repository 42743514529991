import React from "react";
import clsx from "clsx";
import {MicroButtonPropsType} from '../types'
import {MicroButtonStyle} from "../styles";

export function MicroButton({
                                children,
                                icon,
                                size = '14',
                                color = 'light',
                                additionStyles = [],
                                className = MicroButtonStyle,
                                ...props
                            }: MicroButtonPropsType) {

    const getTypeClassName = () => {
        switch (color) {
            case 'light':
                return className?.light
            case 'dark':
                return className?.dark
            case 'color':
                return className?.color
            case 'color-fill':
                return className?.colorFill
            case 'color-hover':
                return className?.colorHover
            case 'red-fill':
                return className?.redFill
            case 'red-hover':
                return className?.redHover
            case 'gray':
                return className?.gray
            case 'gray-fill':
                return className?.grayFill
            case 'blue-light':
                return className?.blueLight
        }
    }

    const getSizeClassName = () => {
        switch (size) {
            case "14":
                return className?.size14
            case "16":
                return className?.size16
            case "20":
                return className?.size20
            case "24":
                return className?.size24
            case "28":
                return className?.size28
        }
    }

    const getAdditionStyles = () => {
        if (typeof additionStyles === 'string') {
            return additionStyles
        }
        return additionStyles.join(' ')
    }

    return (
        <button {...props}
                className={clsx(className?.button, getTypeClassName(), getSizeClassName(), getAdditionStyles())}>
            {icon || children}
        </button>
    )
}