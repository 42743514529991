'use client'

import React, {forwardRef, useState} from "react";
import {IMaskInput} from "react-imask";
import clsx from "clsx";
import {AlertCircleSVG, ChevronDownSVG, HelpCircleSVG, XCloseSVG} from "@/assets";
import {FlexFieldPropsType} from "../types";
import {FlexFieldStyle} from "../styles";

const FlexField = forwardRef<HTMLDivElement, FlexFieldPropsType>(({
                                                                      value,
                                                                      type,
                                                                      onChange,
                                                                      onFocus,
                                                                      onBlur,
                                                                      onClick,
                                                                      mask = /.*/,
                                                                      required = false,
                                                                      disabled = false,
                                                                      isError = false,
                                                                      placeholder,
                                                                      notInput = false,
                                                                      icon,
                                                                      help,
                                                                      dropdown,
                                                                      userPic,
                                                                      textSupport,
                                                                      dropdownState = false,
                                                                      offFocus = false,
                                                                      button,
                                                                      buttonState = 'gray',
                                                                      onButtonClick,
                                                                      className = FlexFieldStyle,
                                                                  }, ref): React.JSX.Element => {
    const [isFocus, setIsFocus] = useState<boolean>(false)

    const handleChange = (newValue: any) => {
        onChange && onChange(newValue)
    }

    const handleBlur = (e: any) => {
        onBlur && onBlur(e)
        setIsFocus(false)
    }

    const handleFocus = (e: any) => {
        onFocus && onFocus(e)
        setIsFocus(true)
    }

    const getInput = () => {
        if (notInput) {
            return (
                <span className={clsx(className?.inputField, {[className?.placeholder]: !value && placeholder})}>
                    {value || placeholder}
                </span>
            )
        }
        let validValue = typeof value === 'string' ? value : ''
        return <IMaskInput value={validValue}
                           type={type}
                           mask={mask}
                           required={required}
                           disabled={disabled}
                           placeholder={placeholder}
                           onAccept={handleChange}
                           onFocus={handleFocus}
                           onBlur={handleBlur}
                           className={className?.inputField}/>
    }

    const getIcon = () => {
        return icon ? <div className={className?.icon}>{icon}</div> : undefined
    }

    const getTextSupport = () => {
        if (!textSupport) return undefined
        if (typeof textSupport === "boolean") {
            return <span className={className?.textSupport}>+7</span>
        } else if (typeof textSupport === "string") {
            return <span className={className?.textSupport}>{textSupport}</span>
        } else {
            return textSupport
        }
    }

    const getUserPic = () => {
        if (typeof userPic === "string") {
            return userPic && <img src={userPic} className={className?.userPic}/>
        }
        return userPic
    }

    const getHelp = () => {
        if (!help) return undefined
        if (typeof help === "boolean") {
            return <button className={className?.help}>{isError ? <AlertCircleSVG/> : <HelpCircleSVG/>}</button>
        } else {
            return help
        }
    }

    const getDropdown = () => {
        if (!dropdown) return undefined
        const dropdownIcon = typeof dropdown === "boolean" ? <ChevronDownSVG/> : dropdown
        let dropdownClassName = typeof dropdownState === 'string' ? dropdownState : clsx(className?.dropdown, {[className?.dropdownReverse]: dropdownState})
        return <span className={dropdownClassName}>{dropdownIcon}</span>
    }

    const getButton = () => {
        if (!button) return undefined
        const buttonIcon = typeof button === "boolean" ? <XCloseSVG/> : button

        let buttonClassName
        if (['red', 'gray'].includes(buttonState)) {
            buttonClassName = clsx(className?.button, {
                [className?.gray]: buttonState === 'gray',
                [className?.red]: buttonState === 'red'
            })
        } else if (typeof buttonState === "string") {
            buttonClassName = buttonState
        } else {
            buttonClassName = ''
        }

        return <span onClick={onButtonClick} className={buttonClassName}>{buttonIcon}</span>
    }

    return (
        <div ref={ref}
             onClick={onClick}
             className={clsx(className?.flexField, {
                 [className?.isError as string]: isError,
                 [className?.disabled as string]: disabled,
                 [className?.focus as string]: isFocus && !offFocus,
                 [className?.pointer as string]: !!onClick,
             })}>
            {getIcon()}
            {getTextSupport()}
            {getUserPic()}
            {getInput()}
            {getHelp()}
            {getDropdown()}
            {getButton()}
        </div>
    )
})

export default FlexField