import clsx from "clsx";
import {XCloseSVG} from "@/assets";
import {MicroButton, MicroButtonSizeType} from "@/ui";
import {TagPropsType} from "../types";
import {TagStyle} from "../styles";
import {TagCount} from "./TagCount";
import {TagCheckbox} from "./TagCheckbox";

export function Tag({
                        active = false,
                        size = 'medium',
                        icon = false,
                        label,
                        checkbox = false,
                        checked = false,
                        actions = 'text-only',
                        onCheckboxClick,
                        onXCloseClick,
                        count,
                    }: TagPropsType) {
    const getXCloseButton = () => {
        let buttonSize: MicroButtonSizeType
        switch (size) {
            case "small":
                buttonSize = '14'
                break
            case "medium":
                buttonSize = '16'
                break
            case "large":
                buttonSize = '20'
                break
        }
        return <MicroButton color={'light'}
                            onClick={onXCloseClick}
                            size={buttonSize}
                            icon={<XCloseSVG/>}/>
    }

    const getIcon = () => {
        if (icon === undefined || icon === false) return undefined
        if (typeof icon === 'boolean') {
            return <span className={TagStyle?.dotIcon}></span>
        }
        return icon
    }

    const getAction = () => {
        if (actions === undefined) return undefined
        switch (actions) {
            case "count":
                return <TagCount count={count} color={active ? 'color' : 'gray'} size={size}/>
            case "x-close":
                return getXCloseButton()
            case "text-only":
                return undefined
        }
    }

    const getLabel = () => {
        if (label === undefined) return undefined
        return <span className={TagStyle?.label}>{label}</span>
    }

    const getCheckbox = () => {
        if (checkbox) {
            return <TagCheckbox onClick={onCheckboxClick}
                                checked={checked}
                                size={size}/>
        }
        return undefined
    }

    return (
        <div className={clsx(TagStyle?.tag, {
            [TagStyle?.active]: active,
            [TagStyle?.small]: size === 'small',
            [TagStyle?.medium]: size === 'medium',
            [TagStyle?.large]: size === 'large',
            [TagStyle?.isAction]: actions !== 'text-only' && actions !== undefined,
            [TagStyle?.isCheckbox]: checkbox,
        })}>
            {getIcon()}
            <div className={TagStyle?.main}>
                {getCheckbox()}
                {getLabel()}
                {getAction()}
            </div>
        </div>
    )
}