import React from "react";
import clsx from "clsx";
import {InputFieldWrapperPropsType} from '../types'
import {InputFieldWrapperStyle} from "../styles";

export default function InputFieldWrapper({
                                              children,
                                              labelText,
                                              hintText,
                                              isErrorHintText,
                                              helperLink,
                                              isError,
                                              viewRequired,
                                              className = InputFieldWrapperStyle,
                                          }: InputFieldWrapperPropsType): React.JSX.Element {
    const getLabelContent = () => {
        if (labelText) {
            return (
                <label className={className?.labelText}>
                    {labelText}{viewRequired && <span className={className?.required}>*</span>}
                </label>
            )
        }
        return undefined
    }

    const getHintContent = () => {
        if (isError && isErrorHintText)
            return <span className={className?.hintError}>{isErrorHintText}</span>

        if (hintText)
            return <span className={className?.hintText}>{hintText}</span>

        return undefined
    }

    const getHelperLinkContent = () => {
        if (helperLink) {
            if ('element' in helperLink)
                return helperLink.element

            if ('href' in helperLink) {
                return <a href={helperLink.href} className={className?.helperLink}>{helperLink.text}</a>
            }

            if ('onClick' in helperLink) {
                return <span onClick={helperLink.onClick} className={className?.helperLink}>{helperLink.text}</span>
            } else {
                return <span className={className?.helperLink}>{helperLink.text}</span>
            }
        }
        return undefined
    }

    return (
        <div className={clsx(className?.inputFieldWrapper, {
            [className?.isError as string]: isError,
        })}>
            {getLabelContent()}
            {children}
            {getHintContent()}
            {getHelperLinkContent()}
        </div>
    )
}