import React, {useState} from "react";
import clsx from "clsx";
import {ToggleBase} from "@/ui";
import {TogglePropsType} from "../types";
import {ToggleStyle} from "../styles";

export function Toggle({
                           active = false,
                           disabled = false,
                           onClick,
                           text,
                           description,
                           additionStyle,
                           width = 'fill',
                           height = 'hug',
                       }: TogglePropsType) {
    const [isHover, setIsHover] = useState(false)
    return (
        <button onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                onClick={onClick}
                className={clsx(ToggleStyle?.toggle, {
                    [ToggleStyle?.widthFill]: width === 'fill',
                    [ToggleStyle?.widthHug]: width === 'hug',
                    [ToggleStyle?.heightFill]: height === 'fill',
                    [ToggleStyle?.heightHug]: height === 'hug',
                    [ToggleStyle?.disabled]: disabled,
                }, additionStyle)}>
            <ToggleBase active={active} hover={isHover} size={'medium'} isButton={false} disabled={disabled}/>
            {text &&
                <div className={ToggleStyle?.textBlock}>
                    <span className={ToggleStyle?.text}>{text}</span>
                    {description && <span className={ToggleStyle?.description}>{description}</span>}
                </div>
            }
        </button>
    )
}