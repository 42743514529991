import {isDict} from "../dict";
import {
    DictDeepActionKeyWayItemDictType,
    DictDeepActionKeyWayItemStringType,
    DictDeepActionKeyWayType
} from "../../types";

export default function getDeepValue(dict: { [key: string]: any }, keyWay: DictDeepActionKeyWayType): any {
    let curPlace: { [key: string]: any } = dict

    for (let i = 0; i < keyWay?.length || 0; i++) {
        if (!isDict(curPlace))
            return undefined

        if (typeof keyWay[i] === 'object') {
            const {name, index} = keyWay[i] as DictDeepActionKeyWayItemDictType
            if (name in curPlace)
                curPlace = curPlace[name][index]
            else
                return undefined

        } else {
            const item = keyWay[i] as DictDeepActionKeyWayItemStringType
            if (item in curPlace)
                curPlace = curPlace[item]
            else
                return undefined
        }
    }

    return curPlace
}