'use client'

import React, {Key, ReactElement} from "react";
import {ARRAY_FIELDS_SCHEMA} from "../schemes";
import {AdditionPropsType} from "../types";
import {formBuilder} from "../lib";

const arrayFieldComponent = (schema: ARRAY_FIELDS_SCHEMA, key: Key, additionProps: AdditionPropsType): ReactElement => {
    const {children, name, ...props} = schema.props || {}
    const wrapper = schema.array_comp ? schema.array_comp : <div/>
    return React.cloneElement(wrapper, {
        ...props,
        key: key,
        children: children && formBuilder(children, {
            ...additionProps,
            // Сброс индексации массива
            arrayKeyWayItemName: name,
            getCurArrayIndex: undefined,
            incrementCurArrayIndex: undefined,
        })
    })
}


export default arrayFieldComponent
