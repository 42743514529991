import React, {useState} from "react";
import {Button, InputField, SlideTransition} from "indicator-ui";
import {CustomerServicePollingStyle} from "../styles";
import {createPoll} from "../api";
import {PollHeader} from "./PollHeader";
import {HasProblems, StarRating} from "./AdditionsComponents";

const POLL_CONFIG = {
    taskInProcess: {
        titleText: 'Бронирование участка',
        hint: 'Вы недавно бронировали участок на сайте bik31.ru. Пожалуйста, оцените удобство процесса бронирования.',
        rating: true,
        question: 'Возникли ли у вас трудности при бронировании?',
        fieldHint: 'Пожалуйста, опишите проблему — мы обязательно постараемся её устранить.',
    },
    showComplete: {
        titleText: 'Показ участка выполнен',
        hint: 'Вы осматривали ранее забронированный участок на сайте bik31.ru. Пожалуйста, оцените работу кадастрового инженера при осмотре.',
        rating: true,
        question: 'Возникли ли у вас проблемы при осмотре участка?',
        fieldHint: 'Пожалуйста, опишите проблему — мы обязательно постараемся её устранить.',
    },
    showCancel: {
        titleText: 'Показ участка отклонён',
        hint: 'Вы осматривали ранее забронированный участок на сайте bik31.ru. Пожалуйста, расскажите, почему вам не понравился участок и почему вы отказались от него.',
        rating: false,
        question: false,
        fieldHint: 'Пожалуйста, опишите причину отказа.',
    },
    taskComplete: {
        titleText: 'Продажа завершена',
        hint: 'Спасибо за приобретение участка. Оцените работу вашего персонального менеджера.',
        rating: true,
        question: 'Возникли ли у вас проблемы при работе с менеджером?',
        fieldHint: 'Пожалуйста, опишите проблему — мы обязательно постараемся её устранить.',
    },
    taskCancel: {
        titleText: 'Отмена бронирования',
        hint: 'Ваше бронирование было отменено. Пожалуйста, расскажите, почему оно было отменено. Что вам не понравилось в нашем клиентском сервисе?',
        rating: false,
        question: false,
        fieldHint: 'Пожалуйста, опишите причину отказа.',
    },
};

export function MainPoll({token, type, setPollState}) {
    const config = POLL_CONFIG[type]
    const [rating, setRating] = useState(undefined)
    const [comment, setComment] = useState(undefined)
    const [hasProblems, setHasProblems] = useState(undefined)

    const [isError, setIsError] = useState({rating: false, hasProblems: false})

    const sendRequest = async () => {
        let finedError = false
        if (config.rating && rating === undefined) {
            finedError = true
            setIsError(prevState => ({...prevState, rating: true}))
        }
        if (config.question && hasProblems === undefined) {
            finedError = true
            setIsError(prevState => ({...prevState, hasProblems: true}))
        }

        if (!finedError) {
            const response = await createPoll({
                token: token,
                comment: comment ?? null,
                grade: rating !== null && rating !== undefined ? rating + 1 : null
            })
            if (response) {
                setPollState('completed')
            } else {
                throw 'Request failed.'
            }
        }
    }

    const onChangeRating = (rating) => {
        if (rating === 4) {
            setHasProblems(null)
            setComment(undefined)
        }
        setRating(rating)
    }

    const onChangeIsProblem = (isProblem) => {
        setIsError(prevState => ({...prevState, hasProblems: false}))
        if (!isProblem) {
            setComment(undefined)
        }
        setHasProblems(isProblem)
    }

    const onChangeComment = (comment) => {
        setComment(comment)
    }

    const getHasProblemsState = () => {
        if (rating === undefined) {
            return 'hide'
        }

        return rating < 4 ? 'enter-up' : 'exit-up'
    }
    const getCommentState = () => {
        if (config.question === false) {
            return 'show'
        }

        if (hasProblems === undefined) {
            return 'hide'
        }

        return hasProblems && rating < 4 ? 'enter-up' : 'exit-up'
    }

    return (
        <div className={CustomerServicePollingStyle?.poll}>
            <PollHeader titleHint={'Опрос по клиентскому сервису'}
                        titleText={config.titleText}
                        hint={config.hint}/>
            {config.rating !== false && <StarRating rating={rating} setRating={onChangeRating}/>}
            {config.question !== false &&
                <SlideTransition animation={getHasProblemsState()}>
                    <HasProblems question={config.question} value={hasProblems} setValue={onChangeIsProblem}
                                 isError={isError.hasProblems}/>
                </SlideTransition>}
            <SlideTransition animation={getCommentState()}>
                <InputField type={'textarea'}
                            labelText={'Оставьте комментарий'}
                            viewRequired={true}
                            value={comment}
                            onChange={onChangeComment}
                            offFocus={true}
                            additionStyles={CustomerServicePollingStyle.field}
                            hintText={config.fieldHint}/>
            </SlideTransition>
            <Button onClick={sendRequest}
                    size={'large'} hierarchy={'primary'} width={'fill'}>Отправить</Button>
        </div>
    )
}