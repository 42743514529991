'use client'

import React, {useEffect} from "react"
import {RadioFieldOptionsItemType, RadioFieldPropsType} from "../types";
import {RadioFieldStyle} from "../styles";
import RadioFieldItem from "./RadioFieldItem";

export default function RadioField({
                                       value,
                                       onChange,
                                       options = [],
                                       required = false,
                                       multiple = true,
                                       disabled = false,
                                       className = RadioFieldStyle,
                                   }: RadioFieldPropsType): React.JSX.Element {
    useEffect(() => {
        if (required && options[0] && (value === undefined || Array.isArray(value) && value.length === 0)) {
            onChange && onChange(options[0].value)
        }
    }, []);

    const handleChange = (option: RadioFieldOptionsItemType) => {
        let newValue: any[]

        if (value) {
            if (Array.isArray(value)) {
                newValue = [...value]
            } else {
                newValue = [value]
            }
        } else {
            newValue = []
        }

        if (!!newValue.find(item => JSON.stringify(item) === JSON.stringify(option.value))) {
            newValue = newValue.filter(item => JSON.stringify(item) !== JSON.stringify(option.value))
        } else {
            newValue = [...newValue, option.value]
        }

        if (required && newValue.length === 0) {
            if (value) {
                if (Array.isArray(value)) {
                    newValue = [...value]
                } else {
                    newValue = [value]
                }
            } else {
                if (options[0])
                    newValue = [options[0]]
            }
        }

        if (!multiple) {
            newValue = [newValue[newValue.length - 1]]
        }

        onChange && onChange(newValue)
    }

    const isActive = (option: RadioFieldOptionsItemType) => {
        if (Array.isArray(value)) {
            return value.find(item => JSON.stringify(item) === JSON.stringify(option.value))
        } else {
            return JSON.stringify(value) === JSON.stringify(option.value)
        }
    }

    return (
        <>
            {options.map((item, idx) => <RadioFieldItem option={item}
                                                        onClick={() => handleChange(item)}
                                                        active={isActive(item)}
                                                        disabled={disabled}
                                                        className={className}
                                                        key={idx}/>)}
        </>
    )
}