import React, {ChangeEvent} from "react";
import clsx from "clsx";
import {TextareaFieldPropsType} from "../types";
import {TextareaFieldStyle} from "../styles";

export default function TextareaField({
                                          value,
                                          name,
                                          placeholder,
                                          onChange,
                                          required = false,
                                          disabled = false,
                                          isError = false,
                                          offFocus = false,
                                          additionStyles,
                                          className = TextareaFieldStyle,
                                      }: TextareaFieldPropsType): React.JSX.Element {
    const handleOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        e.preventDefault()
        onChange && onChange(e.target.value)
    }

    return (
        <textarea name={name}
                  placeholder={placeholder}
                  value={value || ''}
                  onChange={handleOnChange}
                  required={required}
                  disabled={disabled}
                  className={clsx(className?.textareaField, {
                      [className?.isError as string]: isError,
                      [className?.offFocus as string]: offFocus,
                  }, additionStyles)}
        />
    )
}