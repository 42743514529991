import icons from "./assets/icons/icons";
import {CustomerServicePolling} from "./pages/CustomerServicePolling";

export default {

  // логин
  login: {
    path: '/login'
  },
  login_code: {
    path: '/code-accept',
  },
  logout: {
    path: '/logout'
  },
  refresh: {
    path: '/refresh',
  },
  register: {
    path: '/registration',
  },
  cabinet_page: {
    title: "Кабинет",
    icon: "",
    path: "/",
  },
  site_bik: {
    title: "Сайт БИК",
    icon: "",
    path: "https://bik31.ru/",
  },

  // формы
  user_edit: {
    title: "Личные данные",
    icon: "",
    path: "/user_edit",
  },
  user_data_2: {
    title: "Анкета",
    icon: "",
    path: "/anketa",
  },
  buy_land: {
    title: "Купить участок в БИК",
    icon: icons.Home,
    path: "/map",
    query_string: '?type=RESIDENTIAL_PLOTS'
  },
  buy_land_com: {
    title: "Забронировать участок коммерческого назначения",
    icon: icons.BIK,
    path: "/map",
    query_string: '?type=COMMERCIAL_PLOTS'
  },
  buy_land_investor: {
    title: "Инвестору",
    icon: icons.Layers,
    path: "/map",
    query_string: '?type=MULTIFAMILY_HOUSE_PLOTS'
  },
  buy_land_form: {
    title: "Предварительное бронирование участка",
    icon: icons.BIK,
    path: "/buy-land",
  },
  market_form: {
    title: "Купить участок в БИК Маркет",
    icon: icons.Market,
    path: "https://bik31.ru/buy-land/bik-market/",
  },
  sell_land: {
    title: "Продать свой участок в БИК Маркет",
    icon: icons.Sell_land,
    path: "https://bik31.ru/razmestit-svoj-uchastok/",
  },
  fond_izhs_form: {
    title: "Получить займ Фонд поддержки ИЖС",
    icon: icons.Fond,
    path: "https://bik31.ru/kredity-i-zajmy/zajmy-fonda-izhs-2/", // /fond-izhs
  },
  bik_invest_form: {
    title: "Получить займ КПК БИК-Инвест",
    icon: icons.Wallet,
    path: "https://bik31.ru/kredity-i-zajmy/zajmy-bik/", // /bik-invest
  },
  assistant: {
    title: "Интерактивный помощник",
    icon: '',
    path: "https://bik31.ru/assistant/",
  },
  bik_bot: {
    title: "Telegram-бот",
    icon: '',
    path: "https://bik31.ru/servisy/moj-dom-bot/",
  },

  // услуги

  build_selfcontrol: {
    title: "Самоконтроль стадий строительства",
    path: "/build-selfcontrol",
    icon: icons.Camera,
  },
  snyatie_obremeneniya: {
    title: "Заявление на снятие обременения (дом вводится в эксплуатацию)",
    path: "/snyatie-obremeneniya",
    icon: icons.Home,
  },
  snyatie_obremeneniya_v_bank: {
    title: "Заявление на снятие обременения в пользу банка (ипотека)",
    path: "/snyatie-obremeneniya-v-bank",
    icon: icons.Bank,
  },
  razdel_imushestva: {
    title: "Раздел совместно нажитого имущества (развод)",
    path: "/razdel-imushestva",
    icon: icons.Chart,
  },
  otchuzhdenie_na_rodstvennika: {
    title: "Отчуждение на близкого родсвенника (передача прав собственности)",
    path: "/otchuzhdenie-na-rodstvennika",
    icon: icons.UserRight,
  },
  vozvedenie_fundamenta: {
    title: "Уведомление о возведении фундамента",
    path: "/vozvedenie-fundamenta",
    icon: icons.Layers,
  },
  prodlenie_stroitelstva: {
    title: "Заявление о продлении срока строительства",
    path: "/prodlenie-stroitelstva",
    icon: icons.Calendar,
  },
  ustanovka_ios: {
    title: "Установка ИОС",
    path: "/ustanovka-ios",
    icon: icons.Droplets,
  },
  obrashenie_k_gendirektoru: {
    title: "Обращение к генеральному директору АО «БИК» и ГУП «Фонд поддержки ИЖС»",
    path: "https://bik31.ru/obrashenie/",
    icon: icons.Mail,
  },
  soobshit_o_korrupcii: {
    title: "Сообщить о факте коррупции",
    path: "https://bik31.ru/obrashenie/", // /soobshit_o_korrupcii
    icon: icons.CoinsHand,
  },
  soglashenie: {
    title: "Пользовательское соглашение и политика обработки персональных данных",
    path: "https://bik31.ru/polzovatelskoe-soglashenie/",
    icon: ''
  },

  // сервисы

  postroit_dom: {
    title: "Как построить дом в Белгородской области",
    path: "https://newhome.bik31.ru/",
    icon: icons.FileQuestion,
  },
  avarinie_situacii: {
    title: "Аварийные ситуации ",
    path: "https://bik31.ru/district-accident/",
    icon: icons.AlertSquare,
  },
  skidki_i_bonusi: {
    title: "Скидки и бонусы ",
    path: "https://bik31.ru/servisy/skidki-i-bonusy/",
    icon: icons.Gift,
  },
  calculator: {
    title: "Кредитный калькулятор ",
    path: "https://bik31.ru/kredity-i-zajmy/creditcalculator/",
    icon: '',
  },
};
