import React, {useCallback} from 'react'

import './LandMapPage.scss'
import SimplePage from '../../components/SimplePage/SimplePage'
import MapPage from '../../components/MapPage/MapPage'
import {useLocation} from 'react-router-dom'
import urls from '../../urls'
import {commertialContent, investoruContent} from "./pageContent";


const LandMapPage = () => {
    const loction = useLocation()

    const getTitle = useCallback(() => {
        const params = new URLSearchParams(loction.search)
        const landplotsType = params.get('type')
        switch (landplotsType) {
            case 'RESIDENTIAL_PLOTS':
                return <h1 className='map-page-title'>{urls.buy_land.title}</h1>
            case 'COMMERCIAL_PLOTS':
                return <h1 className='map-page-title'>{urls.buy_land_com.title}</h1>
            case 'MULTIFAMILY_HOUSE_PLOTS':
                return <h1 className='map-page-title'>{urls.buy_land_investor.title}</h1>
            default:
                return <h1 className='map-page-title'>{urls.buy_land.title}</h1>
        }
    }, [loction])

    const getContent = useCallback(() => {
        const params = new URLSearchParams(loction.search)
        const landplotsType = params.get('type')
        switch (landplotsType) {
            case 'RESIDENTIAL_PLOTS':
                return <></>
            case 'COMMERCIAL_PLOTS':
                return commertialContent
            case 'MULTIFAMILY_HOUSE_PLOTS':
                return investoruContent
            default:
                return <></>
        }
    }, [loction])

    return (
        <SimplePage>
            {getTitle()}
            <MapPage/>
            <br/>
            <br/>
            {getContent()}
            <div className='map-info'>
                <p className='map-info__text _bold'>На данный момент система работает в режиме тестирования: могут
                    возникать сбои в бронировании
                    и существует возможность бронирования одного и того же участка несколько раз разными клиентам.
                    Мы оставляем за собой право закреплять бронирование за первым человеком, оставившим заявку.
                    Приносим извинения за неудобства.</p>

                <p className='map-info__text'>Предварительное бронирование не является публичной офертой, носит
                    исключительно ознакомительный
                    характер с предлагаемыми земельными участками для продажи. Предварительное бронирование
                    работает только на один забронированный участок, последующие бронирования участков не принимаются.
                    Цены на земельные участки не являются окончательными и могут быть изменены продавцом
                    в одностороннем порядке до заключения договора купли-продажи.</p>
            </div>
        </SimplePage>
    )
}

export default LandMapPage