import React from "react";
import {ChevronDownSVG, ChevronUpSVG} from "../../../assets";
import {DatePickerHeaderPropsType, MicroButton} from "@/ui";
import {DatePickerHeaderStyle} from "@/ui/DatePicker/styles";

export function DatePickerHeader({onUpClick, onDownClick}: DatePickerHeaderPropsType) {
    return (
        <div className={DatePickerHeaderStyle.datePickerHeader}>
            <span className={DatePickerHeaderStyle.text}>Выбор даты</span>
            <div className={DatePickerHeaderStyle.switchers}>
                <MicroButton type={'button'} onClick={onUpClick} color={'color'} size={'24'}
                             additionStyles={DatePickerHeaderStyle.item}><ChevronUpSVG/></MicroButton>
                <MicroButton type={'button'} onClick={onDownClick} color={'color'} size={'24'}
                             additionStyles={DatePickerHeaderStyle.item}><ChevronDownSVG/></MicroButton>
            </div>
        </div>
    )
}