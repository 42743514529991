import React from 'react';
import {Button} from "indicator-ui";
import {CustomerServicePollingStyle} from "../styles";
import {PollHeader} from "./PollHeader";
import {ArrowNarrowRightSVG} from "../assets";

export function CompletedPoll() {
    return (
        <div className={CustomerServicePollingStyle?.poll}>
            <PollHeader titleHint={'Ответ отправлен'}
                        titleText={'Спасибо за участие в опросе!'}
                        hint={'Специалисты БИК обработают вашу оценку и свяжутся с вами, если будет необходимо уточнить детали.'}/>
            <Button customComponent={<a href={'https://bik31.ru/'}/>}
                    size={'medium'}
                    hierarchy={'link-color'}
                    iconRight={<ArrowNarrowRightSVG/>}>
                Перейти на сайт БИК
            </Button>
        </div>
    )
}