import React from "react";
import {SelectFieldOptionsItemType, SelectModalWindowPropsType} from "../types";
import {isActiveOption} from "../lib";
import {DELTA_SCROLL_END} from "../config";
import SelectFieldOptionsItem from "./SelectFieldOptionsItem";

export default function SelectModalWindow({
                                              value,
                                              onChange,
                                              options,
                                              onScrollToBottom,
                                              afterElement,
                                              className,
                                          }: SelectModalWindowPropsType): React.JSX.Element {
    const handleClick = (option: SelectFieldOptionsItemType) => {
        let newValue: any[]

        if (value) {
            if (Array.isArray(value)) {
                newValue = [...value]
            } else {
                newValue = [value]
            }
        } else {
            newValue = []
        }

        newValue.find(item => item === option.value)

        if (!!newValue.find(item => JSON.stringify(item) === JSON.stringify(option.value))) {
            onChange && onChange(newValue.filter(item => JSON.stringify(item) !== JSON.stringify(option.value)))
        } else {
            onChange([...newValue, option.value])
        }
    }

    const handleScroll: React.UIEventHandler<HTMLDivElement> = (event) => {
        const scrollHeight = event.currentTarget.scrollHeight
        const clientHeight = event.currentTarget.clientHeight
        const scrollTop = event.currentTarget.scrollTop
        console.log('catch')
        if (scrollHeight - scrollTop - clientHeight < DELTA_SCROLL_END) {
            onScrollToBottom && onScrollToBottom()
        }
    }

    return (
        <div onScroll={handleScroll} className={className?.selectModalWindow}>
            {options.map((item, idx) => <SelectFieldOptionsItem option={item}
                                                                active={isActiveOption(value, item)}
                                                                onClick={() => handleClick(item)}
                                                                className={className}
                                                                key={idx}/>)}
            {afterElement}
        </div>
    )
}