import React, {useState} from 'react'
import clsx from "clsx";
import {CheckboxMark} from "@/ui";
import {CheckboxPropsType} from "../types";
import {CheckboxStyle} from "../styles";

export function Checkbox({
                             checked = false,
                             type = 'checkbox',
                             error = false,
                             subtype = false,
                             disabled = false,
                             text,
                             supportingText,
                             indeterminate = false,
                             onClick,
                             width = 'fill',
                             height = 'hug',
                             className = CheckboxStyle,
                         }: CheckboxPropsType) {
    const [isHover, setIsHover] = useState(false)
    return (
        <button onClick={onClick}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                className={clsx(className?.checkbox, {
                    [className?.error]: error,
                    [className?.disabled]: disabled,
                    [className?.widthFill]: width === 'fill',
                    [className?.widthHug]: width === 'hug',
                    [className?.heightFill]: height === 'fill',
                    [className?.heightHug]: height === 'hug',
                })}>
            <CheckboxMark type={type}
                          indeterminate={indeterminate}
                          disabled={disabled}
                          error={error}
                          checked={checked}
                          isButton={false}
                          hover={isHover}
                          relation={subtype}/>
            {text &&
                <div className={clsx(className?.textBlock, {[className?.indeterminate]: indeterminate})}>
                    <div className={className?.text}>{text}</div>
                    {supportingText && <div className={className?.supportingText}>{supportingText}</div>}
                </div>
            }
        </button>
    )
}