import React, {useEffect, useRef} from "react";
import {SlideTransitionStyle} from '../styles'
import {SlideTransitionAnimationType, SlideTransitionPropsType} from '../types'
import clsx from "clsx";

/**
 * ##### Компонент для анимации прокрутки вверх и вниз.
 * Для контроля анимации используйте пропс ```animation```.
 *
 * ---
 * ```'enter-down'``` - производит анимацию появления снизу.
 *
 * ```'enter-up'``` - производит анимацию появления сверху.
 *
 * ```'exit-down'``` - производит анимацию скрытия снизу.
 *
 * ```'exit-up'``` - производит анимацию скрытия сверху.
 *
 *  ```'hide'``` - изначально скрывает элемент.
 *
 * ```'show'``` - изначально показывает элемент.
 *
 * ---
 * Учтите, что анимации **"снизу"** сужают компонент, поэтому он будет деформироваться.
 * При высокой (автор проводил тесты на 300ms) скорости анимации это незаметно (если вы не Флеш).
 * */
export function SlideTransition({
                                    children,
                                    animation,
                                    additionStyles,
                                    animationOptions = {duration: 300, easing: 'ease-in-out', fill: 'forwards'}
                                }: SlideTransitionPropsType) {
    const mainRef = useRef<HTMLDivElement>(null)
    const animateDropWrapperRef = useRef<HTMLDivElement>(null)
    const lastClientHeight = useRef(undefined);

    useEffect(() => {
        lastClientHeight.current = mainRef.current.clientHeight
        if (animation === 'hide') {
            animateDropWrapperRef.current.style.display = 'none'
        } else if (animation === 'show') {
            animateDropWrapperRef.current.style.display = 'block'
        }
    }, []);

    const getConfig = (type: SlideTransitionAnimationType) => {
        if (type in animationOptions) {
            return animationOptions[type]
        }
        return animationOptions
    }

    const animateExitDown = () => {
        const animateConfig = getConfig('exit-down')
        if (animateDropWrapperRef.current && mainRef.current) {
            animateDropWrapperRef.current.style.display = 'block'
            const animateDropWrapper = animateDropWrapperRef.current.animate([
                {transform: 'scaleY(1)', transformOrigin: 'bottom'},
                {transform: 'scaleY(0)', transformOrigin: 'bottom'},
            ], animateConfig)
            mainRef.current.animate([
                {transform: 'translateY(0)'},
                {transform: 'translateY(100%)'},
            ], animateConfig)
            animateDropWrapper.finished.then(() => {
                animateDropWrapperRef.current.style.display = `none`
            })
        }
    }
    const animateExitUp = () => {
        const animateConfig = getConfig('exit-up')
        if (animateDropWrapperRef.current && mainRef.current) {
            const mainHeight = mainRef.current.clientHeight
            animateDropWrapperRef.current.style.display = 'block'
            lastClientHeight.current = mainHeight
            const animateDropWrapper = animateDropWrapperRef.current.animate([
                {height: `${mainHeight}px`},
                {height: `0`},
            ], animateConfig)
            mainRef.current.animate([
                {transform: 'translateY(0)'},
                {transform: 'translateY(-100%)'},
            ], animateConfig)
            animateDropWrapper.finished.then(() => {
                animateDropWrapperRef.current.style.display = `none`
            })
        }
    }
    const animateEnterDown = () => {
        const animateConfig = getConfig('enter-down')
        if (animateDropWrapperRef.current && mainRef.current) {
            animateDropWrapperRef.current.style.display = 'block';
            animateDropWrapperRef.current.animate([
                {transform: 'scaleY(0)', transformOrigin: 'bottom', display: 'block'},
                {transform: 'scaleY(1)', transformOrigin: 'bottom'},
            ], animateConfig)
            mainRef.current.animate([
                {transform: 'translateY(100%)'},
                {transform: 'translateY(0)'},
            ], animateConfig)
        }
    }
    const animateEnterUp = () => {
        const animateConfig = getConfig('enter-up');
        if (animateDropWrapperRef.current && mainRef.current) {
            animateDropWrapperRef.current.style.display = 'block';
            const mainHeight = mainRef.current.clientHeight || lastClientHeight.current;
            animateDropWrapperRef.current.animate([
                {height: `0`},
                {height: `${mainHeight}px`},
            ], animateConfig);
            mainRef.current.animate([
                {transform: 'translateY(-100%)'},
                {transform: 'translateY(0)'},
            ], animateConfig);
        }
    }

    useEffect(() => {
        switch (animation) {
            case 'exit-down':
                animateExitDown()
                break
            case "exit-up":
                animateExitUp()
                break
            case "enter-down":
                animateEnterDown()
                break
            case "enter-up":
                animateEnterUp()
                break
        }
    }, [animation]);

    return (
        <div ref={animateDropWrapperRef}
             className={clsx(SlideTransitionStyle.animateDrop, additionStyles || [])}>
            <div ref={mainRef}
                 className={SlideTransitionStyle.main}>
                {children}
            </div>
        </div>
    )
}