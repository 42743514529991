'use client'

import {useEffect, useState} from "react";
import {DictDeepActionKeyWayType} from "../types";
import {isEqualArrays} from "../lib";

export type UseIsErrorFieldNameType = string | DictDeepActionKeyWayType
export type UseIsErrorFieldIsErrorItemType = { name: UseIsErrorFieldNameType, errorMessage?: string }
export type UseIsErrorFieldIsErrorType = UseIsErrorFieldIsErrorItemType[]
export type UseIsErrorFieldIsErrorFieldType = (name: UseIsErrorFieldNameType) => boolean
export type UseIsErrorFieldGetErrorMessageType = (name: UseIsErrorFieldNameType) => string | undefined
export type UseIsErrorFieldAddErrorFieldType = (name: UseIsErrorFieldNameType, errorMessage?: string) => void
export type UseIsErrorFieldRemoveErrorFieldType = (name: UseIsErrorFieldNameType) => void

export type UseIsErrorFieldReturnType = {
    isErrorField: UseIsErrorFieldIsErrorFieldType,
    addErrorField: UseIsErrorFieldAddErrorFieldType,
    removeErrorField: UseIsErrorFieldRemoveErrorFieldType,
    getErrorMessage: UseIsErrorFieldGetErrorMessageType,
    clearErrors: () => void,
    isError: UseIsErrorFieldIsErrorType
}

const notSensitiveCheckNameField = (value: UseIsErrorFieldNameType, field: UseIsErrorFieldNameType) => {
    if (value.length === 0 || field.length === 0) {
        return false
    }

    let i = 0
    for (let j = 0; j < value.length && i < field.length; j++) {
        if (JSON.stringify(value[j]) === JSON.stringify(field[i])) {
            i++
        }
        j++
    }
    return i === field.length
}

const checkNameField = (value: UseIsErrorFieldNameType, field: UseIsErrorFieldNameType) => {
    const convertValue = typeof value === "object" && value.length === 1 ? value[0] : value
    const convertField = typeof field === "object" && field.length === 1 ? field[0] : field
    return convertValue.toString() === convertField.toString()
}

const comp = (a: UseIsErrorFieldNameType, b: UseIsErrorFieldNameType) => {
    const arrA = typeof a === 'object' ? a : [a]
    const arrB = typeof b === 'object' ? b : [b]
    const length = arrA.length
    return length !== 0 && isEqualArrays(arrA.slice(0, length), arrB.slice(0, length))
}

export default function useIsErrorField({
                                            additionIsErrorFields = [],
                                            sensitiveSearch = true,
                                        }:
                                            {
                                                additionIsErrorFields?: UseIsErrorFieldIsErrorType,
                                                sensitiveSearch?: boolean,
                                            } = {}): UseIsErrorFieldReturnType {
    const [isError, setIsError] = useState<UseIsErrorFieldIsErrorType>([])

    useEffect(() => {
        if (additionIsErrorFields && additionIsErrorFields.length > 0)
            setIsError(prevState => [...prevState, ...additionIsErrorFields])
    }, [JSON.stringify(additionIsErrorFields)])
    // }, [additionIsErrorFields])

    const isErrorField: UseIsErrorFieldIsErrorFieldType = (name) => {
        const criterion = (value: UseIsErrorFieldIsErrorItemType) => sensitiveSearch ? checkNameField(value.name, name) : notSensitiveCheckNameField(value.name, name)
        return isError.some(criterion)
    }

    const addErrorField: UseIsErrorFieldAddErrorFieldType = (name, errorMessage) => {
        setIsError(prevState => {
            const newSet = [...prevState.filter(value => !checkNameField(value.name, name))]
            return [...newSet, {name: name, errorMessage: errorMessage}]
        })
    }

    const removeErrorField: UseIsErrorFieldRemoveErrorFieldType = (name) => {
        setIsError(prevState => {
            const newSet = [...prevState]
            return newSet.filter(value => !comp(name, value.name))
        })
    }

    const getErrorMessage: UseIsErrorFieldGetErrorMessageType = (name) => {
        const criterion = (value: UseIsErrorFieldIsErrorItemType) => checkNameField(value.name, name)
        return isError.find(criterion)?.errorMessage
    }

    const clearErrors = () => {
        setIsError([])
    }

    return {
        isErrorField: isErrorField,
        addErrorField: addErrorField,
        removeErrorField: removeErrorField,
        getErrorMessage: getErrorMessage,
        clearErrors: clearErrors,
        isError: isError
    }
}