import React, {Key, ReactElement} from "react";
import {isCustomComponent} from "@/lib";
import {BLOCK_WRAPPER_SCHEMA} from "../schemes";
import {AdditionPropsType} from "../types";
import {formBuilder} from "../lib";

const blockWrapperComponent = (schema: BLOCK_WRAPPER_SCHEMA, key: Key, additionProps: AdditionPropsType): ReactElement => {
    const {children, ...props} = schema.props || {}
    const comp = schema.block_wrapper ? schema.block_wrapper : <></>
    return React.cloneElement(comp, {
        ...props,
        ...(isCustomComponent(comp) ? additionProps.customCompAdditionProps : {}),
        children: children ? formBuilder(children, {
            ...additionProps,
            arrayKeyWayItemName: additionProps.arrayKeyWayItemName,
            getCurArrayIndex: additionProps.arrayKeyWayItemName ? additionProps.getCurArrayIndex : undefined,
            incrementCurArrayIndex: additionProps.arrayKeyWayItemName ? additionProps.incrementCurArrayIndex : undefined,
        }) : [],
        key: key,
    })
}

export default blockWrapperComponent