import React, {useState} from 'react'
import {StarIcon} from './StarIcon'
import {StarRatingStyle} from "../../styles";

const rationArray = [
    'Плохо',
    'Неудовлетворительно',
    'Средне',
    'Хорошо',
    'Отлично',
]

const hoverLabel = 'Оцените от 1 до 5'

export function StarRating({rating, setRating}) {
    const [hoverIndex, setHoverIndex] = useState(undefined)

    const onMouseEnter = (idx) => {
        setHoverIndex(idx)
    }
    const onMouseLeave = (idx) => {
        setHoverIndex(prevState => {
            if (prevState === idx) {
                return undefined
            }
            return prevState
        })
    }

    const getLabel = () => {
        let resLabel
        if (rating === undefined) {
            resLabel = hoverLabel
        } else {
            resLabel = rationArray[hoverIndex ?? rating]
        }
        return <span className={StarRatingStyle.label}>{resLabel}</span>
    }

    const getStars = () => {
        return (
            <div>{Array.from({length: rationArray.length}, (_item, idx) => (
                <StarIcon onMouseEnter={() => onMouseEnter(idx)}
                          onMouseLeave={() => onMouseLeave(idx)}
                          active={(hoverIndex === undefined && rating >= idx) || (hoverIndex >= idx && rating !== undefined)}
                          onClick={() => setRating(idx)}
                          hover={hoverIndex !== undefined && hoverIndex >= idx}
                          key={idx}/>
            ))}</div>
        )
    }

    return (
        <div className={StarRatingStyle.starRating}>
            {getStars()}
            {getLabel()}
        </div>
    )
}
