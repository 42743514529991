import React, {useEffect, useRef} from "react";
import clsx from "clsx";
import {DateGridPropsType} from "../types";
import {DateGridStyle} from "../styles";
import {CalendarDay} from "./CalendarDay";

export function DateGrid({
                             grid,
                             onClickDate,
                             onScrollUp,
                             onScrollDown,
                             state
                         }: DateGridPropsType) {
    const elementRef = useRef<HTMLDivElement | null>(null)
    const lastTouchY = useRef(0);

    useEffect(() => {
        const onTouchMove = (event: TouchEvent) => {
            const touchY = event.touches[0].clientY;
            if (touchY < lastTouchY.current) {
                onScrollUp && onScrollUp()
            } else if (touchY > lastTouchY.current) {
                onScrollDown && onScrollDown()
            }
            lastTouchY.current = touchY;
        };

        if (elementRef.current) {
            elementRef.current.addEventListener("touchmove", onTouchMove);
        }

        return () => {
            elementRef.current?.removeEventListener("touchmove", onTouchMove);
        };
    }, []);

    const getStateClassName = () => {
        switch (state) {
            case 'upEntrance':
                return DateGridStyle.upEntrance
            case 'downEntrance':
                return DateGridStyle.downEntrance
            case 'upExit':
                return DateGridStyle.upExit
            case 'downExit':
                return DateGridStyle.downExit
            case 'display-none':
                return DateGridStyle.displayNone
            case undefined:
                return ''
        }
    }

    return (
        <div className={clsx(DateGridStyle.dateGrid, getStateClassName())}>
            {grid?.map((item) => <div id={item.id} className={DateGridStyle.item} key={item.date}>
                <CalendarDay {...item} onClick={() => onClickDate && onClickDate(item.date)}/></div>)}
        </div>
    )
}