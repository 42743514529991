import React from "react";
import {CustomerServicePollingStyle} from "../styles";

export function PollHeader({titleHint, titleText, hint}) {
    return (
        <div className={CustomerServicePollingStyle?.text}>
            <div className={CustomerServicePollingStyle?.title}>
                <div className={CustomerServicePollingStyle?.titleHint}>{titleHint}</div>
                <div className={CustomerServicePollingStyle?.titleText}>{titleText}</div>
            </div>
            <div className={CustomerServicePollingStyle?.hint}>{hint}</div>
        </div>
    )
}