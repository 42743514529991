import React, {Key, ReactNode} from "react";
import {REACT_NODE_SCHEMA} from "../schemes";
import {AdditionPropsType} from '../types';

const reactNodeComponent = (schema: REACT_NODE_SCHEMA, key: Key, _additionProps: AdditionPropsType): ReactNode => {
    if (React.isValidElement(schema.react_node_comp))
        return React.cloneElement(schema.react_node_comp, {key: key})
    return schema.react_node_comp
}

export default reactNodeComponent