import {isDict} from "../../lib";
import {isArray} from "../../lib";
import {DictDeepActionKeyWayItemType, DictDeepActionKeyWayType} from "../../types";

export default function setDeepValue(dict: { [key: string]: any }, value: any, options: {
    keysWay: DictDeepActionKeyWayType
}): {
    [key: string]: any
} {
    let newDict = {...dict}
    let writePlace = newDict
    const lastIndexOfKeysWay = options.keysWay.length - 1
    options.keysWay.forEach((key: DictDeepActionKeyWayItemType, idx: number) => {
        let curKey: DictDeepActionKeyWayItemType | number = key
        if (typeof curKey === 'object') {
            const {name, index} = curKey

            if (!isArray(writePlace[name]))
                writePlace[name] = []

            writePlace = writePlace[name]
            curKey = index
        }

        if (!isDict(writePlace[curKey])) {
            writePlace[curKey] = {}
        }

        if (idx < lastIndexOfKeysWay)
            writePlace = writePlace[curKey]
        else
            writePlace[curKey] = value
    })

    return newDict
}