import React, {Key, ReactElement} from "react";
import {isCustomComponent} from "@/lib";
import {FORM_WRAPPER_SCHEMA} from "../schemes";
import {AdditionPropsType} from "../types";
import {formBuilder} from "../lib";

const formWrapperComponent = (schema: FORM_WRAPPER_SCHEMA, key: Key, additionProps: AdditionPropsType): ReactElement => {
    const newKeyWay = schema.props.name ? [...additionProps.keyWay, schema.props.name] : additionProps.keyWay
    const Element = schema.form_wrapper ? schema.form_wrapper : <div/>
    return React.cloneElement(Element, {
        ...{...schema.props},
        key: key,
        ...(isCustomComponent(Element) ? additionProps.customCompAdditionProps : {}),
        children: schema.props.children && formBuilder(schema.props.children, {
            ...additionProps,
            // Сброс индексации массива
            arrayKeyWayItemName: undefined,
            getCurArrayIndex: undefined,
            incrementCurArrayIndex: undefined,
            keyWay: newKeyWay
        }),
    })
}

export default formWrapperComponent