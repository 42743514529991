'use client'

import {Dispatch, SetStateAction, useState} from "react";
import {getDeepValue, setDeepValue} from "../lib";
import {DictDeepActionKeyWayItemType, DictDeepActionKeyWayType} from "../types";

export type UseFormDataSetValueType = (value: any, keyWay: DictDeepActionKeyWayType, options?: {
    unionToArray: boolean,
    index?: number
}) => void

export type UseFormDataGetValueType = (keyWay: DictDeepActionKeyWayType) => any
export type UseFormDataDeleteArrayItemType = (keyWay: DictDeepActionKeyWayType) => void

export type UseFormDataReturnType<T> = {
    formData: T,
    setFormData: Dispatch<SetStateAction<T>>,
    setValue: UseFormDataSetValueType,
    getValue: UseFormDataGetValueType,
    resetForm: () => void,
    clearForm: () => void,
    deleteArrayItem: UseFormDataDeleteArrayItemType,
}

export default function useFormData<T extends Record<string, any>>(value?: T): UseFormDataReturnType<T> {
    const [formData, setFormData] = useState<T>(value || {} as T)

    const setValue: UseFormDataSetValueType = (value, keyWay, options = {unionToArray: false}): void => {
        setFormData(prevState => {
            let newValue = value

            if (options.unionToArray) {
                const curValue = getDeepValue(prevState, keyWay)
                if (Array.isArray(curValue)) {
                    if (options.index !== undefined && options.index < curValue.length && options.index >= 0) {
                        newValue = [...curValue]
                        newValue[options.index] = value
                    } else {
                        newValue = [...curValue, newValue]
                    }
                } else {
                    if (options.index) {
                        if (options.index === 0)
                            newValue = [newValue]
                        else
                            newValue = curValue ? [curValue, newValue] : [newValue]
                    } else {
                        newValue = curValue ? [curValue, newValue] : [newValue]
                    }
                }
            }

            return {...setDeepValue(prevState, newValue, {keysWay: keyWay})} as T
        })
    }

    const getValue: UseFormDataGetValueType = (keyWay): string => {
        return getDeepValue(formData, keyWay)
    }

    const resetForm = () => {
        setFormData(value || {} as T)
    }

    const clearForm = () => {
        setFormData({} as T)
    }

    const deleteArrayItem: UseFormDataDeleteArrayItemType = (keyWay) => {
        const lastKeyWay: DictDeepActionKeyWayItemType | undefined = keyWay.length > 0 && keyWay[keyWay.length - 1] || undefined
        if (lastKeyWay && typeof lastKeyWay === 'object') {
            const arrayKeyWay = [...keyWay.slice(0, keyWay.length - 1), lastKeyWay.name]
            const oldValue = getValue(arrayKeyWay).filter((_item: any, idx: number) => idx !== lastKeyWay.index)
            setValue(oldValue, arrayKeyWay)
        }
    }

    return {
        formData: formData,
        setFormData: setFormData,
        setValue: setValue,
        getValue: getValue,
        resetForm: resetForm,
        clearForm: clearForm,
        deleteArrayItem: deleteArrayItem
    }
}
