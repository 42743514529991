import React from "react";
import clsx from "clsx";
import {ToggleBasePropsType} from "../types";
import {ToggleBaseStyle} from "../styles";

export function ToggleBase({
                               active = false,
                               onClick,
                               error = false,
                               size = 'medium',
                               hover = false,
                               disabled = false,
                               isButton = true,
                               className = ToggleBaseStyle,
                           }: ToggleBasePropsType) {
    const getClassName = () => {
        return clsx(className?.toggleBase, {
            [className?.active]: active,
            [className?.error]: error,
            [className?.hover]: hover,
            [className?.disabled]: disabled,
            [className?.large]: size === 'large',
            [className?.medium]: size === 'medium',
        })
    }

    const componentWrapper = isButton ? <button/> : <span/>
    return React.cloneElement(componentWrapper, {
        children: <span className={className?.button}/>,
        onClick: onClick,
        className: getClassName(),
    })
}