import React from "react";
import {Checkbox} from "indicator-ui";
import {HasProblemsStyle} from '../../styles'

export function HasProblems({value, setValue, isError, question}) {
    return (
        <div className={HasProblemsStyle.hasProblem}>
            <div className={HasProblemsStyle.label}>{question}</div>
            <div className={HasProblemsStyle.fields}>
                <Checkbox type={'radio'}
                          text={'Да'}
                          width={'hug'}
                          error={isError}
                          onClick={() => setValue(true)}
                          checked={value === true}/>
                <Checkbox type={'radio'}
                          text={'Нет'}
                          width={'hug'}
                          error={isError}
                          onClick={() => setValue(false)}
                          checked={value === false}/>
            </div>
        </div>
    )
}