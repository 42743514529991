import React, {forwardRef} from "react";
import clsx from "clsx";
import {CheckSVG, MinusSVG, XCloseSVG} from "@/assets";
import {CheckboxMarkPropsType} from "../types";
import {CheckboxMarkStyle} from "../styles";

export const CheckboxMark = forwardRef<HTMLButtonElement | HTMLSpanElement, CheckboxMarkPropsType>(({
                                                                                                        type = "checkbox",
                                                                                                        indeterminate = false,
                                                                                                        checked = false,
                                                                                                        error = false,
                                                                                                        relation = false,
                                                                                                        disabled = false,
                                                                                                        isButton = true,
                                                                                                        hover = false,
                                                                                                        onClick,
                                                                                                        additionStyle,
                                                                                                        className = CheckboxMarkStyle
                                                                                                    }, ref) => {
    const getClassName = () => {
        return clsx(className?.checkboxMark, {
            [className?.checkbox]: type === 'checkbox',
            [className?.radio]: type === 'radio',
            [className?.checked]: checked,
            [className?.disabled]: disabled,
            [className?.error]: error,
            [className?.correct]: relation === 'correct',
            [className?.incorrect]: relation === 'incorrect',
            [className?.indeterminate]: indeterminate,
            [className?.hover]: hover,
        }, additionStyle)
    }

    const getIcon = () => {
        if (indeterminate) {
            if (typeof indeterminate === 'boolean') {
                return <MinusSVG/>
            }
            return indeterminate
        }
        if (checked) {
            if (relation === 'correct') return <CheckSVG/>
            if (relation === 'incorrect') return <XCloseSVG/>
            if (type === 'checkbox') return <CheckSVG/>
        }
        return undefined
    }

    const handleOnClick = () => {
        if (onClick && !disabled) {
            onClick()
        }
    }

    const wrapperComponent = isButton ? <button/> : <span/>
    return React.cloneElement(wrapperComponent, {
        ref: ref,
        onClick: handleOnClick,
        className: getClassName(),
        children: getIcon(),
    })
})