import React from "react";
import {Link} from "react-router-dom";

export const commertialContent = <>
    <h2 className='map-page-title-h2'>АО «БИК» предлагает земельные участки коммерческого назначения для реализации
        будущих проектов</h2>
    <p className='stupid-map-info__top-text'>Почему стоит приобрести земельный участок для бизнеса в АО «БИК»?</p>
    <div className='stupid-map-info'>
        <div>
            <p className='stupid-map-info__text _bold'>Подбор земельного участка</p>
            <p className='stupid-map-info__text'>Персональный менеджер подберет земельный участок для любых целей вашего
                бизнеса</p>
        </div>
        <div>
            <p className='stupid-map-info__text _bold'>Снижение стоимости</p>
            <p className='stupid-map-info__text'>Стоимость земельного участка может быть снижена при предоставлении
                проектной документации на планируемый коммерческий объект</p>
        </div>
        <div>
            <p className='stupid-map-info__text _bold'>Возможность преобразования</p>
            <p className='stupid-map-info__text'>Земельный участок может быть преобразован в соответствии с желаемыми
                параметрами (площадь, вид разрешенного использования, категория и т.п.).</p>
        </div>
        <div>
            <p className='stupid-map-info__text _bold'>Вынос в натуру границ земельного участка</p>
            <p className='stupid-map-info__text'>Специалисты АО «БИК» осуществляют показ земельного участка на местности
                с определением его границ до сделки купли-продажи</p>
        </div>
        <div>
            <p className='stupid-map-info__text _bold'>Лояльные условия оплаты</p>
            <p className='stupid-map-info__text'>Возможность внесения платежей в рассрочку до 12 месяцев</p>
        </div>
    </div>
    <p className='stupid-map-info__bottom-text'>Не упустите возможность приобрести идеальный земельный участок для
        бизнеса! Свяжитесь с нами сегодня и узнайте больше о наших услугах </p>
</>

export const investoruContent = <div className={'map-page-content'}>
    <h2 className='map-page-title-h2'>Инвестору</h2>
    <p>Акционерное общество «Белгородская ипотечная корпорация» — региональный земельный оператор со 100% долей участия
        Правительства Белгородской области в уставном капитале.</p>
    <p>БИК предлагает Инвесторам-застройщикам приобрести земельные участки для реализации инвестиционных проектов по
        строительству жилья. Земельные участки предлагаются без торгов, по стоимости значительно ниже, чем участки
        коммерческого назначения. Стоимость 1 м2 земли зависит от площади ввода жилья. Распространяется на строительство
        индивидуального, многоквартирного жилья и блокированной застройки.</p>
    <p>Менеджеры отдела продаж подберут для Вашего проекта земельный участок соответствующей площади и назначения,
        проконсультируют по наличию инженерных коммуникаций, окажут полное информационное сопровождение на всех этапах
        реализации проекта.</p>
    <p>
        <b>Инвестор-застройщик, намеревающийся заключить инвестиционное соглашение должен соответствовать следующим <Link to={'https://bik31.ru/media/site_platform_media/2025/3/17/trebovaniya.pdf'}
                  target={'_blank'} download={'Требования по инвестиционному соглашению'} alt={'Требования по инвестиционному соглашению'}>критериям</Link>.</b>
    </p>
    <p>Заместитель исполнительного директора: <b>Марковских Даниил Александрович</b>
        <br/>
        <Link to={'tel:88003012931'}>8 800 301 29 31</Link> (доб. 1126)
        <br/>
        <Link to={'mailto:markovskih_da@mbik31.ru'}>markovskih_da@mbik31.ru</Link>
    </p>


</div>
