import React from "react"
import {
    FlexField,
    InputFieldWrapper,
    RadioField,
    SelectField,
    SwitcherField,
    TextareaField,
} from "../../../InputFields";
import {InputFieldStyle} from "../styles";
import {InputFieldPropsType} from "../types";

export default function InputField({type, offWrapper, className = InputFieldStyle, ...props}: InputFieldPropsType): React.JSX.Element {
    const getField = () => {
        switch (type) {
            case 'select':
                return <SelectField {...props} className={className}/>
            case 'radio':
                return <RadioField {...props} className={className}/>
            case 'switcher':
                return <SwitcherField {...props} className={className}/>
            case 'textarea':
                return <TextareaField {...props} className={className}/>
            default:
                return <FlexField  {...props} type={type} className={className}/>
        }
    }

    if (offWrapper) {
        return getField()
    }

    return <InputFieldWrapper {...props} viewRequired={props.viewRequired !== undefined? props.viewRequired : props.required}  className={className}>{getField()}</InputFieldWrapper>
}