import React, {useState} from "react";
import clsx from "clsx";
import {Checkbox} from "@/ui";
import {traverseTreeByStack} from "@/lib";
import {CascadeBlockPropsType} from "../types";
import {CascadeBlockStyle} from "../styles";
import {ChevronDownSVG} from "@/assets";

export function CascadeBlock({
                                 options,
                                 onClick,
                                 label,
                                 supportingText,
                                 noWrapper = false,
                                 defaultAllHidden = false,
                                 value
                             }: CascadeBlockPropsType) {
    const [isOpen, setIsOpen] = useState(defaultAllHidden)

    const isActive = (keyValue: any): boolean => {
        return value.includes(keyValue)
    }

    const getCountOfActiveCheckbox = (): 'all' | 'some' | 'empty' => {
        let totalCount = 0
        let count = 0
        traverseTreeByStack(options, (item) => {
            if ('value' in item) {
                totalCount++
                if (value.includes(item.value)) count++
            }
        }, {
            getArray: (item) => {
                if ('options' in item) {
                    return item.options
                }
                return undefined
            }
        })
        if (count === totalCount && totalCount !== 0) {
            return 'all'
        } else if (count === 0) {
            return 'empty'
        }
        return 'some'
    }

    const getCascade = () => {
        return options.map((item, idx) => {
            if ('options' in item) {
                return <CascadeBlock value={value}
                                     onClick={onClick}
                                     label={item.label}
                                     supportingText={item.supportingText}
                                     options={item.options}
                                     key={idx}/>
            } else if ('value' in item) {
                return <Checkbox text={item.label}
                                 supportingText={item.supportingText}
                                 width={'hug'}
                                 checked={isActive(item.value)}
                                 onClick={() => onClick && onClick(item.value)}
                                 key={idx}/>
            }
        })
    }

    if (noWrapper) {
        return getCascade()
    }

    const cascadeState = getCountOfActiveCheckbox()
    return (
        <div>
            <Checkbox supportingText={supportingText}
                      text={<div
                          className={clsx(CascadeBlockStyle?.cascadeBlockLabel, {[CascadeBlockStyle?.open]: isOpen})}>
                          <span className={CascadeBlockStyle?.text}>{label}</span>
                          <ChevronDownSVG/>
                      </div>}
                      width={'hug'}
                      checked={cascadeState === 'all'}
                      indeterminate={cascadeState === 'some'}
                      onClick={() => setIsOpen(prev => !prev)}/>
            {options.length > 0 && <div className={CascadeBlockStyle?.cascadeBlock}>
                <div className={clsx(CascadeBlockStyle.list, {[CascadeBlockStyle?.hide]: isOpen})}>
                    {getCascade()}
                </div>
            </div>}
        </div>
    )
}