import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setUserDataItem } from '../../../store/userDataSlice';
import ContragentRequest from '../../../api/cabinet/Contragent';
import FormItem from '../../UI/fields/FormItem';
import urls from '../../../urls';
import { onPassportInput, onPhoneInput } from '../../../functions/mask';
import error from '../../../functions/error';

const UserDataForm = ({ title }) => {
    const contragentRequest = new ContragentRequest()
    const navigate = useNavigate();
    const userData = useSelector(state => state.user_data.userData)
    const dispatch = useDispatch()
    const addUserData = (name, value) => dispatch(setUserDataItem([name, value]))
    const [btnActive, setBtnActive] = useState(false)
    const [formError, setFormError] = useState(false)
    const [fields, setFields] = useState([
        {
            id: 'phone',
            name: 'phone',
            type: 'text',
            label: 'Номер телефона',
            required: true,
            read_only: true,
            mask: onPhoneInput,
        },
        {
            id: 'fio',
            name: 'fio',
            type: 'text',
            label: 'ФИО',
            read_only: true,
            required: true,
        },
        {
            id: 'email',
            name: 'email',
            type: 'email',
            label: 'Электронная почта',
            required: true,
        },
        {
            id: 'birth_date',
            name: 'birth_date',
            type: 'text',
            label: 'Дата рождения',
            required: true,
        },
        {
            id: 'passport',
            name: 'passport',
            type: 'text',
            label: 'Серия и номер паспорта',
            placeholder: '00 00 000000',
            mask: onPassportInput,
            required: true,
        },
        {
            id: 'fakt_address',
            name: 'fakt_address',
            type: 'text',
            label: 'Адрес регистрации',
            required: true,
        }
    ])

    const [data, setData] = useState({})

    useEffect(() => {
        if (userData.contragent) {
            contragentRequest.getById(userData.contragent)
                .then(response => {
                    console.log('response', response)
                    if (response.success) {
                        for (let key in response.data.data) {
                            if (key)
                                data[key] = response.data.data[key]
                            setData({ ...data })
                        }
                    } else {
                        setFormError(error(response))
                    }
                })
        } else {
            console.log('не залогинен')
        }
    }, [])

    const setValue = (name, value) => {
        data[name] = value
        // value ?? delete data[name]
        // if (value === '')
        //     delete data[name]    //если не отправить поле оно не изменится на бэке
        setData({ ...data })
        // if (data.phone && data.fio && data.email && data.birth_date) {
        //     setBtnActive(true)
        // } else {
        //     setBtnActive(false)
        // }
    }

    const setError = (name, value) => {
        const index = fields.findIndex(field => field.name === name)
        fields[index].error = value
        setFields(fields)
    }

    const send = () => {
        setBtnActive(false)
        const form_data = data
        form_data.id = userData.contragent
        contragentRequest.update({ "contragent": form_data })
            .then(response => {
                if (response.success) {
                    if (response.data.data.id)
                        addUserData('contragent', response.data.data.id)
                    navigate(urls.cabinet_page.path)
                } else {
                    setFormError(error(response))
                }
                setBtnActive(true)
            })
    }

    return (
        <>
            <h1 className='form-card__title-24'>{title}</h1>
            <p className='form-card__description'>Все поля обязательные</p>
            {formError && <p className='form__error'>{formError}</p>}
            {fields.map(item => (
                <FormItem field={item} key={item.id} mask={item.mask || false} value={data[item.name]} setValue={setValue} setError={setError} />
            ))}
            <span className={`form-btn _block mt-16 ${(data.phone && data.fio && data.email && data.birth_date) ? '' : '_disabled'}`} onClick={send}>Сохранить</span>
        </>
    )
}

export default UserDataForm