import clsx from "clsx";
import {CheckSVG} from "@/assets";
import {TagCheckboxPropsType} from '../types'
import {TagCheckboxStyle} from '../styles'

export function TagCheckbox({size = 'medium', checked = false, disabled = false, onClick}: TagCheckboxPropsType) {
    return (
        <button onClick={onClick} className={clsx(TagCheckboxStyle?.tagCheckbox, {
            [TagCheckboxStyle.checked]: checked,
            [TagCheckboxStyle.small]: size === 'small',
            [TagCheckboxStyle.medium]: size === 'medium',
            [TagCheckboxStyle.large]: size === 'large',
            [TagCheckboxStyle.disabled]: disabled,
        })}><CheckSVG/></button>
    )
}