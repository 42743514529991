import React from "react";
import {CSSProperties, ReactNode, useEffect, useRef} from "react";

export function ModalWindowWrapper({
                                               children,
                                               isShow = false,
                                               setIsShow,
                                               additionElements = [],
                                               onClose,
                                               className,
                                               style,
                                           }:
                                               {
                                                   isShow: boolean,
                                                   setIsShow: (isShow: boolean) => void,
                                                   additionElements?: (HTMLElement | null | undefined)[],
                                                   children?: ReactNode,
                                                   onClose?: () => void,
                                                   className?: string,
                                                   style?: CSSProperties,
                                               }) {

    const modalWindowRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleMouseDown = (e: MouseEvent) => {
            if (!isShow) return
            const target = e.target
            if (target !== modalWindowRef.current &&
                !modalWindowRef.current?.contains(target as Node) &&
                !additionElements.filter(item => item !== null).find(item => {
                    return item === target || item?.contains(target as Node)
                })
            ) {
                onClose && onClose()
                setIsShow(false)
            }
        }

        document.addEventListener('mousedown', handleMouseDown)
        return () => {
            document.removeEventListener('mousedown', handleMouseDown)
        }
    }, [additionElements])

    return isShow && (
        <div ref={modalWindowRef} style={style} className={className}>
            {children}
        </div>
    )
}