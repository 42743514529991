import React from "react";
import clsx from "clsx";
import {ButtonPropsType} from '../types'
import {ButtonsStyle} from "../styles";

export function Button({
                           children,
                           text,
                           iconLeft,
                           iconRight,
                           hierarchy = 'primary',
                           warning = false,
                           size = 'medium',
                           width = 'hug',
                           height = 'hug',
                           additionStyles = [],
                           customComponent,
                           className = ButtonsStyle,
                           ...props
                       }: ButtonPropsType) {
    const getHierarchyClassName = () => {
        switch (hierarchy) {
            case "primary":
                return className?.primary
            case "secondary-color":
                return className?.secondaryColor
            case "secondary-gray":
                return className?.secondaryGray
            case "tertiary-white":
                return className?.tertiaryWhite
            case "tertiary-color":
                return className?.tertiaryColor
            case "tertiary-gray":
                return className?.tertiaryGray
            case "link-color":
                return className?.linkColor
            case "link-black":
                return className?.linkBlack
            case "link-gray":
                return className?.linkGray
            case "tab":
                return className?.tab
            case "tab-active":
                return className?.tabActive
        }
    }

    const getSizeClassName = () => {
        switch (size) {
            case "small":
                return className?.small
            case "medium":
                return className?.medium
            case "large":
                return className?.large
            case "ultra":
                return className?.ultra
        }
    }

    const getWidthClassName = () => {
        switch (width) {
            case "fill":
                return className?.widthFill
            case "hug":
                return className?.widthHug
        }
    }

    const getHeightClassName = () => {
        switch (height) {
            case "fill":
                return className?.heightFill
            case "hug":
                return className?.heightHug
        }
    }

    const getAdditionStyles = () => {
        if (typeof additionStyles === 'string') {
            return additionStyles
        }
        return additionStyles.join(' ')
    }

    const getWarningStyles = () => {
        return warning ? className?.warning : ''
    }

    const getMainContent = () => {
        return (
            <>
                {iconLeft && <span className={className?.icon}>{iconLeft}</span>}
                {children && <span className={className?.text}>{text || children}</span>}
                {iconRight && <span className={className?.icon}>{iconRight}</span>}
            </>
        )
    }

    const getClassName = () => {
        return clsx(className?.button, getHierarchyClassName(), getSizeClassName(), getWidthClassName(), getHeightClassName(), getAdditionStyles(), getWarningStyles())
    }

    if (customComponent !== undefined) {
        return React.cloneElement(customComponent, {
            children: getMainContent(),
            className: getClassName()
        })
    }

    return (
        <button {...props}
                className={getClassName()}>
            {getMainContent()}
        </button>
    )
}