import React, {useEffect, useRef, useState} from "react";
import {FlexField} from '../../FlexField'
import {SelectFieldOptionsType, SelectFieldPropsType} from '../types';
import {getStringValue} from '../lib';
import {SelectFieldStyle} from "../styles";
import SelectModalWindow from "./SelectModalWindow";
import {ModalWindowWrapper} from "@/ui";

export default function SelectField({
                                        value,
                                        onChange,
                                        options = [],
                                        placeholder,
                                        isError,
                                        searching,
                                        required = false,
                                        multiple = false,
                                        disabled = false,
                                        offOptionsSyncing = true,
                                        offInitLoadMoreOptions = false,
                                        autoClose = false,
                                        onBlur,
                                        selectModalWindowAfterElement,
                                        loadMoreOptions,
                                        className = SelectFieldStyle,
                                    }: SelectFieldPropsType): React.JSX.Element {
    const [isFocus, setIsFocus] = useState<boolean>(false)
    const [searchingString, setSearchingString] = useState<string>('')
    const [isShowModalWindow, setIsShowModalWindow] = useState<boolean>(false)

    const requestPromiseRef = useRef<Promise<any> | undefined>(undefined);

    // Добавляем кэширование выбранных options
    const optionsCacheRef = useRef<SelectFieldOptionsType>([]);

    const [optionsState, setOptionsState] = useState<SelectFieldOptionsType>(options)
    const inputRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (!offInitLoadMoreOptions) {
            onScrollToBottom()
        }
    }, []);

    useEffect(() => {
        if (!offOptionsSyncing)
            setOptionsState(options)
    }, [options]);

    const handleChange = (data: any[]) => {
        optionsCacheRef.current = optionsState.filter(item => data.includes(item.value))

        if (data.length === 0 && required) {
            let newValue

            if (Array.isArray(value)) {
                newValue = value[0]
            } else {
                newValue = value
            }

            onChange && onChange([newValue || optionsState[0]])
        } else {
            if (data.length > 1 && !multiple) {
                onChange && onChange([data[data.length - 1]])
                if (autoClose) {
                    setIsShowModalWindow(false)
                    onBlur && onBlur()
                }
            } else {
                onChange && onChange(data)
            }
        }
    }

    const getViewField = () => {
        if (searching !== undefined) {
            return <FlexField value={isFocus ? searchingString : getStringValue(value, optionsCacheRef.current)}
                              onChange={(searchString) => {
                                  if (isFocus) {
                                      setSearchingString(searchString)
                                      searching(searchString, optionsState).then((newOptions) => newOptions !== undefined && setOptionsState(newOptions))
                                  }
                              }}
                              disabled={disabled}
                              onFocus={() => {
                                  setIsFocus(true)
                                  setIsShowModalWindow(true)
                              }}
                              onBlur={() => {
                                  setIsFocus(false)
                                  onBlur && onBlur()
                              }}
                              onClick={() => setIsShowModalWindow(true)}
                              isError={isError}
                              placeholder={placeholder}
                              dropdown={true}
                              dropdownState={isShowModalWindow}
                              ref={inputRef}
            />
        }

        return <FlexField value={getStringValue(value, optionsCacheRef.current)}
                          isError={isError}
                          disabled={disabled}
                          placeholder={placeholder}
                          notInput={true}
                          onClick={() => setIsShowModalWindow(true)}
                          dropdown={true}
                          dropdownState={isShowModalWindow}
                          ref={inputRef}
        />
    }

    const onScrollToBottom = async () => {
        if (loadMoreOptions) {
            const requestPromise = requestPromiseRef.current
            if (requestPromise === undefined) {
                requestPromiseRef.current = loadMoreOptions(optionsState?.length || 0, searchingString)
            }
            const newOptions = await requestPromiseRef.current
            if (requestPromise === undefined || requestPromise === requestPromiseRef.current) {
                requestPromiseRef.current = undefined
            }
            if (newOptions !== undefined) setOptionsState(prevState => [...prevState, ...newOptions])
        }
    }

    return (
        <div className={className?.selectField}>
            {getViewField()}
            <ModalWindowWrapper isShow={isShowModalWindow}
                                setIsShow={setIsShowModalWindow}
                                additionElements={[inputRef.current]}
                                className={className?.modalWindow}>
                <SelectModalWindow value={value}
                                   options={optionsState}
                                   onChange={handleChange}
                                   afterElement={selectModalWindowAfterElement}
                                   onScrollToBottom={onScrollToBottom}
                                   className={className}/>
            </ModalWindowWrapper>
        </div>
    )
}