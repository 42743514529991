import React, {useState} from "react";
import {addMonths, format, subMonths} from "date-fns";
import {DatePickerStyle} from "../styles";
import {DateGridOnClickDate, DatePickerChosenIntervalType, DatePickerPropsType} from "../types";
import {getInitChosenInterval, getInitTargetDate, getNewInterval} from "../lib";
import {DatePickerMain} from "./DatePickerMain";
import {DatePickerHeader} from "./DatePickerHeader";
import {DatePickerFooter} from "./DatePickerFooter";

export function DatePicker({
                               chosenStart,
                               chosenEnd,
                               markFun,
                               infoFun,
                               onSubmit,
                               formatOut,
                               formatIn,
                               onCancel,
                               multiple = true
                           }: DatePickerPropsType) {
    const [targetDate, setTargetDate] = useState(getInitTargetDate({
        start: chosenStart,
        end: chosenEnd,
        formatIn: formatIn
    }))
    const [chosenInterval, setChosenInterval] = useState<DatePickerChosenIntervalType>(getInitChosenInterval({
        start: chosenStart,
        end: chosenEnd,
        multiple: multiple,
        formatIn: formatIn
    }))

    const onUpClick = () => {
        setTargetDate(prevState => subMonths(prevState, 1).toISOString())
    }

    const onDownClick = () => {
        setTargetDate(prevState => addMonths(prevState, 1).toISOString())
    }

    const handleSubmit = () => {
        const start = chosenInterval.start ? format(chosenInterval.start, formatOut || 'yyyy-MM-dd') : undefined
        const end = chosenInterval.end ? format(chosenInterval.end, formatOut || 'yyyy-MM-dd') : undefined
        onSubmit && onSubmit({start: start, end: end})
    }

    const onClickDate: DateGridOnClickDate = (date) => {
        setChosenInterval(prevState => {
            if (multiple) {
                return getNewInterval(prevState, date)
            }
            return {start: date}
        })
    }

    return (
        <div className={DatePickerStyle.datePicker}>
            <DatePickerHeader onUpClick={onUpClick} onDownClick={onDownClick}/>
            <DatePickerMain target={targetDate}
                            chosenStart={chosenInterval.start}
                            chosenEnd={chosenInterval.end}
                            infoFun={infoFun}
                            onClickDate={onClickDate}
                            onScrollUp={onUpClick}
                            onScrollDown={onDownClick}
                            markFun={markFun}/>
            <DatePickerFooter onSubmit={handleSubmit} onCancel={onCancel}/>
        </div>
    )
}