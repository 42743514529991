import React from "react";
import {Button} from "@/ui";
import {DatePickerFooterPropsType} from "../types";
import {DatePickerFooterStyle} from "../styles";

export function DatePickerFooter({onCancel, onSubmit}: DatePickerFooterPropsType) {
    return (
        <div className={DatePickerFooterStyle.datePickerFooter}>
            <div className={DatePickerFooterStyle.item}><Button type={'button'} onClick={onCancel}
                                                                hierarchy={'secondary-gray'}
                                                                size={'small'} width={'fill'}>Отмена</Button></div>
            <div className={DatePickerFooterStyle.item}><Button type={'button'} onClick={onSubmit} hierarchy={'primary'}
                                                                size={'small'}
                                                                width={'fill'}>Применить</Button></div>
        </div>
    )
}