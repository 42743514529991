import React from "react";
import clsx from "clsx";
import {CheckSVG} from "@/assets";
import {SelectFieldOptionsItemPropsType} from "../types";

export default function SelectFieldOptionsItem({option, active, onClick, className}: SelectFieldOptionsItemPropsType): React.JSX.Element {
    if (option.component) {
        return React.cloneElement(option.component, {onClick: onClick, active: active});
    }

    return (
        <button onClick={onClick}
                className={clsx(className?.selectFieldOptionsItem, {
                    [className?.active as string]: active,
                })}>

            <span className={className?.label}>{option.label}</span>
            {active && <CheckSVG/>}
        </button>
    )
}